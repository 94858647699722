<template>
    <div >
        <v-text-field
            label="Buscar"
            v-model="query"
            class="mx-2"
            clearable
        />
        <v-treeview
            :active.sync="med"
            :items="medicos"
            item-text="text"
            item-key="id"
            item-disabled="disabled"
            :open-on-click="true"
            shaped
            hoverable
            activatable
            :search="query"
            dense
        />
    </div>
</template>

<script>
export default {
    mounted(){
        //console.log(this.$store.getters['security/datosUsuario'])
        this.cargaMedicos()
    },
    props:{
        medico:Array,
    },

    data:()=>({
        loadingMed:false,
        active:[],
        medicos:[],
        activo:[],
        query:'',
        //especialidades:[],
    }),

    methods:{
        async cargaCitas(med){
            console.log(med[0])
        },
        async cargaMedicos(){
            this.loadingMed=true
            try{
                const req =await this.$http({
                    url:'/consulta/medicos',
                    method:'GET',
                })
                this.loadingMed=false
                this.medicos = req.data
            }catch(err){
                this.loadingMed=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        med:{
            get(){return this.medico},
            set(v){this.$emit('update:medico', v)}

        },
    },
    watch:{
        activo(val){
            this.cargaCitas(val)
        }
    },
}
</script>

<style>

</style>